import { Spin } from 'antd';
import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useUndoRedoContext } from 'src/hooks/useUndoRedoContext';
import NotFoundPage from 'src/pages/NotFound';
import Landing from 'src/pages/landing/Landing';

export const privateRoutes = [
  {
    path: '/login',
    element: lazy(() => import('../pages/login/Login')),
    auth: true,
  },
  {
    path: '/setting',
    element: lazy(() => import('../pages/setting/Index')),
    auth: true,
  },
  {
    path: '/forgot-password',
    element: lazy(() => import('../pages/forgot-password/ForgotPassword')),
    auth: false,
  },
  {
    path: '/reset-password/:id/:token',
    element: lazy(() => import('../pages/reset-password/ResetPassword')),
    auth: false,
  },
  {
    path: '/check-token-reset-password/:id/:token',
    element: lazy(() => import('../pages/reset-password/ResetPassword')),
    auth: false,
  },
];

const ConfigRoutes = () => {
  const { undo, redo } = useUndoRedoContext();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check for Ctrl or Cmd key
      const isCtrlOrCmd = event.ctrlKey || event.metaKey;

      if (isCtrlOrCmd && event.key === 'z') {
        undo();
        event.preventDefault();
      }

      if (isCtrlOrCmd && event.key === 'y') {
        redo();
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/home" element={<Landing />} />
      <Route path="/" element={<Landing />}>
        {privateRoutes.map((route, index) => {
          return (
            <Route
              path={route.path}
              element={
                <React.Suspense
                  fallback={
                    <Spin spinning wrapperClassName="spinning-route">
                      <div
                        style={{
                          width: '100vw',
                          height: '100vh',
                        }}
                      />
                    </Spin>
                  }
                >
                  {<route.element />}
                </React.Suspense>
              }
              key={index}
            ></Route>
          );
        })}
      </Route>
    </Routes>
  );
};

export default ConfigRoutes;
