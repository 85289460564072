import { ConfigProvider } from 'antd';
import Landing from './pages/landing/Landing';
import useHandleZoom from './hooks/useHandleZoom';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStateProvider } from './context/GlobalStateContext';
import { useGlobalErrorHandler } from './hooks/useErrorGlobalHandler';
import './App.css';
import ConfigRoutes from './routes/ConfigRoutes';
import GlobalStyles from './styles/utils/GlobalStyles/GlobalStyles';
import { Loading } from './components/Loading';
import { AuthContextProvider } from './context/AuthContext';
import { UndoRedoProvider } from './context/UndoRedoContext';

function App() {
  const { fontSize } = useHandleZoom();
  useGlobalErrorHandler();

  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Arial',
            fontSize: fontSize,
          },
        }}
      >
        <GlobalStateProvider>
          <AuthContextProvider>
            <UndoRedoProvider>
              <GlobalStyles>
                <Loading>
                  <div className="App">
                    <div className="wrapper">
                      <div className="container">
                        <div className="content">
                          <ConfigRoutes></ConfigRoutes>
                        </div>
                      </div>
                    </div>
                  </div>
                </Loading>
              </GlobalStyles>
            </UndoRedoProvider>
          </AuthContextProvider>
        </GlobalStateProvider>
      </ConfigProvider>
    </Router>
  );
}

export default App;
