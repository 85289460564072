import axios from 'axios';
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useNavigateSearch from 'src/hooks/useNavigateSearch';
import useStoreGlobal from 'src/hooks/useStoreGlobal';
import authService from 'src/services/auth.service';
import { ERR_NETWORK } from 'src/utils/constants/http';
import { ROUTES } from 'src/routes/routes';
import { KEY } from 'src/configs/app';
import { IAuthReducerInitSate } from 'src/types';

const appUser: string | null = localStorage.getItem('userInfo');

const initState: IAuthReducerInitSate = {
  userInfo: appUser ? JSON.parse(appUser) : null,
  isAuth: appUser ? true : false,
  setAuth: null,
};

const AuthContext = createContext(initState);

function AuthContextProvider(props: any) {
  const [user, setUser] = useState<IAuthReducerInitSate>(initState);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useNavigateSearch();
  const storeGlobal = useStoreGlobal();

  function isTokenExpired(token: any) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }

  const redirectLogin = (isError?: number) => {
    if (window.location.pathname !== ROUTES.LOGIN) {
      const search = searchParams({
        prevURL: window.location.pathname ? window.location.pathname : ROUTES.HOME,
        searchURL: window.location.search,
        isError: isError ?? '0',
      });

      storeGlobal.setIsLoading(false);

      const newState = { page: 'login' };
      window.history.replaceState(newState, 'Login', ROUTES.LOGIN);
      window.location.replace('/redirect' + window.location.pathname !== ROUTES.LOGIN ? search : location.search);
    }
  };

  function authCheck() {
    const storeUser = localStorage.getItem(KEY.keyAuthLocal);
    const parseUser = storeUser ? JSON.parse(storeUser) : { user: null, jwt: '' };
    if (!storeUser || !parseUser?.user || !parseUser?.jwt || (parseUser?.jwt && isTokenExpired(parseUser.jwt))) {
      redirectLogin();
    }
  }

  async function loadApiUsers() {
    storeGlobal.setIsLoading(true);
    await authService
      .getCurrentUser()
      .then((userInfo: any) => {
        if (!userInfo.jwt) {
          localStorage.clear();
          return;
        }
        if (userInfo?.jwt && isTokenExpired(userInfo.jwt)) {
          redirectLogin();
          return;
        }
        if (!userInfo.isError) {
          localStorage.setItem(KEY.keyAuthLocal, JSON.stringify(userInfo));
          setUser({
            userInfo: userInfo,
            isAuth: true,
            setAuth: setUser,
          });
          if ([ROUTES.HOME, ROUTES.HOME_2, ROUTES.LOGIN].includes(location?.pathname)) {
            navigate(ROUTES.SETTING);
            return;
          }
        }
      })
      .finally(() => {
        storeGlobal.setIsLoading(false);
      });
  }

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      async (error) => {
        // Catch when server not responding
        if (ERR_NETWORK === error.code) {
          redirectLogin(503);
        }
        // Catch when authentication error
        if ([401, 403].indexOf(error?.response?.status) > -1) {
          redirectLogin(503);
          storeGlobal.setIsLoading(false);
        }

        return Promise.reject(error);
      },
    );
    loadApiUsers();
  }, [location]);

  useEffect(() => {
    if (
      location.pathname !== ROUTES.HOME &&
      location.pathname !== ROUTES.HOME_2 &&
      location.pathname !== ROUTES.LOGIN &&
      location.pathname !== ROUTES.FORGOT_PASSWORD &&
      !location.pathname.includes('reset-password') &&
      !location.pathname.includes('check-token-reset-password')
    ) {
      authCheck();
    }
  }, [location]);

  return <AuthContext.Provider value={user}>{props.children}</AuthContext.Provider>;
}
export { AuthContext, AuthContextProvider };
