export const PATH = {
  REFRESH_TOKEN: 'auth/refresh-token',
  USER: 'auth/user',
  EDIT_PROFILE: 'auth/user/edit-profile',
  ACTIVE_USER: 'auth/user/active',
  DEACTIVATE_USER: 'auth/user/deactivate',
  LOG_IN: 'auth/login',
  FORGOT_PASSWORD: 'auth/forgot-password',
  RESET_PASSWORD: 'auth/reset-password',
  CHANGE_PASSWORD: 'auth/change-password',
  CHECK_TOKEN_RESET_PASSWORD: '/auth/check-token-reset-password',
  LOG_OUT: 'auth/logout',
  PERMISSIONS: 'auth/permissions',
  PROJECT_SEARCH: '/eco-projects',
  SUB_PROJECT_SEARCH: '/eco-sub-projects',
  TOPICS_OPTION: '/eco-topics-options',
  HELP: '/eco-helps',
  PROJECT_IMPORT: '/eco-projects/import',
  ECO_PERMISSION_SUB_PROJECT_ACCESS: '/eco-sub-project-accesses',
  ECO_PERMISSION_PROJECT_ACCESS: '/eco-project-accesses',
  ECO_PERMISSION_ACTION: '/eco-permission-actions',

  ECO_SALES_PERMISSION_PROJECT_ACCESS: '/eco-sales-project-accesses',
  ECO_SALES_PERMISSION_ACTION: '/eco-sales-permission-actions',

  PROJECT_DATA_ALL: '/eco-projects/list-all',
  PROJECT_SALES_DATA_ALL: '/eco-sales-projects/list-all',
};
