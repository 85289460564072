import { createContext, useMemo, useState } from 'react';

interface IStoreGlobalContext {
  isLoading: boolean;
  setIsLoading: any;
  infoSubProject: any;
  setInfoSubProject: any;
  openEditProject?: boolean;
}

const StoreGlobalContext = createContext<IStoreGlobalContext>({
  isLoading: false,
  setIsLoading: null,
  infoSubProject: null,
  setInfoSubProject: null,
});

function GlobalStateProvider(props: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [infoSubProject, setInfoSubProject] = useState();
  const storeGlobal = useMemo(
    () => ({
      isLoading: isLoading,
      setIsLoading,
      infoSubProject: infoSubProject,
      setInfoSubProject,
    }),
    [isLoading, setIsLoading, infoSubProject, setInfoSubProject],
  );

  return <StoreGlobalContext.Provider value={storeGlobal}>{props.children}</StoreGlobalContext.Provider>;
}
export { StoreGlobalContext, GlobalStateProvider };
