import { notification } from 'antd';
import { NOTIFY_TYPE } from '../constants/enums';
import _ from 'lodash';
import { IToastConfig } from 'src/types';

export const getCookieValue = (key: any) => {
  const allCookies = document.cookie;
  let storedValue = '';
  if (allCookies) {
    const cookieArray = allCookies.split(';');
    const filteredCookie = cookieArray.filter((element: any) => {
      const singleCookie = element.split('=');
      if (singleCookie[0].trim() === key) {
        storedValue = singleCookie[1];
      }
      return storedValue;
    });
  }
  return storedValue;
};

/**
 * Open notification dialog
 * @param config
 * @param type
 */
export const OpenToast = (config: IToastConfig, type: string, className?: string) => {
  notification.config({
    top: 80,
    className,
  });

  switch (type) {
    case NOTIFY_TYPE.SUCCESS:
      notification.success(config);
      break;
    case NOTIFY_TYPE.ERROR:
      notification.error(config);
      break;
    case NOTIFY_TYPE.INFO:
      notification.info(config);
      break;
    case NOTIFY_TYPE.WARNING:
      notification.warning(config);
      break;
    default:
      notification.open(config);
      break;
  }
};
