import { Button, Result, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/routes/routes';
import LogoEco from '../assets/icon/logo.svg';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className="pages__container"
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <header className="header">
        <div className="inner">
          <div className="logo-link">
            <img src={LogoEco} alt="Logo" className="logo" />
            <span className="logo-brand">EcoSpace™</span>
          </div>
        </div>
      </header>
      <Result
        subTitle={<Typography.Text style={{ color: '#000' }}>Sorry, the page you visited does not exist.</Typography.Text>}
        extra={
          <Button onClick={() => navigate(ROUTES.HOME)} type="default">
            Back to Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundPage;
