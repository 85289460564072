import { useContext } from 'react';
import { UndoRedoContext, UndoRedoState } from 'src/context/UndoRedoContext';

export const useUndoRedoContext = (): UndoRedoState & {
  setFieldFocus: (data: string) => void;
  clearFieldFocus: () => void;
  setFormValue: (data: Record<string, any>) => void;
  undo: () => void;
  redo: () => void;
  resetFormHistory: () => void;
  resetFormState: () => void;
} => {
  const context = useContext(UndoRedoContext);
  if (!context) {
    throw new Error('useUndoRedoContext must be used within a UndoRedoProvider');
  }
  return context;
};
