export enum REQUEST_METHOD {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export enum PAGE {
  PAGE_SIZE_10 = 10,
  PAGE_SIZE_15 = 15,
  PAGE_SIZE_20 = 20,
  PAGE_SIZE_25 = 25,
  PAGE_SIZE_30 = 30,
}
export const enum ROLE {
  ADMIN = 7,
  USER = 8,
  PROJECT_MANAGER = 6,
}

export const DATE_PATTERN = {
  YYYY_MM_DD: 'YYYY/MM/DD',
  YYYY_MM_DD_HH_mm_ss: 'YYYY/MM/DD HH:mm:ss',
  DD_MM_YYYY: 'DD/MM/YYYY',
  YYYY: 'YYYY',
  MM_YYYY: 'MM/YYYY',
  ISO: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
};

export const NOTIFY_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const PAGINATION = {
  DEFAULT_PAGE: 1,
  DEFAULT_PAGE_SIZE: 10,
  PAGE_SIZE_OPTION: [10, 15, 20, 25, 30],
  DEFAULT_TOTAL_PAGE: 1,
};

export const DEFAULT_LIMIT_SIZE = 100;
