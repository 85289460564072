import * as React from 'react';
import { Tooltip } from 'antd';

export interface TooltipProps {
  titleNode: any;
  childrenNode: any;
  placement?: any;
}

const AppToolTip = (props: TooltipProps) => {
  const { titleNode, childrenNode, placement } = props;
  return (
    <>
      <Tooltip placement={placement} color="#fff" title={titleNode}>
        {childrenNode}
      </Tooltip>
    </>
  );
};
export default AppToolTip;
