// export routes on application

export const ROUTES = {
  HOME: '/',
  HOME_2: '/home',
  SETTING: '/setting',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id/:token',
  CHECK_TOKEN_RESET_PASSWORD: '/check-token-reset-password/:id/:token',
};
