import React, { createContext, useReducer, ReactNode } from 'react';

const ACTIONS = {
  SET_FOCUS: 'SET_FOCUS',
  REMOVE_FOCUS: 'REMOVE_FOCUS',
  SET_VALUE: 'SET_VALUE',
  UNDO: 'UNDO',
  REDO: 'REDO',
  RESET_HISTORY: 'RESET_HISTORY',
  RESET_STATE: 'RESET_STATE',
};

const MAX_HISTORY_LENGTH = 500;

interface UndoRedoState {
  focusField: string;
  formValue: Record<string, any>;
  formHistory: Array<{ formValue: Record<string, any>; focusField: string }>;
  formFuture: Array<{ formValue: Record<string, any>; focusField: string }>;
}

interface UndoRedoContextType extends UndoRedoState {
  setFieldFocus: (data: string) => void;
  clearFieldFocus: () => void;
  setFormValue: (data: Record<string, any>) => void;
  undo: () => void;
  redo: () => void;
  resetFormHistory: () => void;
  resetFormState: () => void;
}

interface UndoRedoProviderProps {
  children: ReactNode;
}

const undoRedoReducer = (state: UndoRedoState, action: any): UndoRedoState => {
  switch (action.type) {
    case ACTIONS.SET_FOCUS:
      return { ...state, focusField: action.payload };
    case ACTIONS.REMOVE_FOCUS:
      return { ...state, focusField: '' };
    case ACTIONS.SET_VALUE:
      if (action.payload?.isInit) {
        return {
          ...state,
          formValue: { ...action.payload, isInit: false },
          formHistory: [{ formValue: { ...action.payload, isInit: false }, focusField: state.focusField }],
          formFuture: [],
        };
      }

      // Remove future history
      if (state.formHistory.length > MAX_HISTORY_LENGTH) {
        state.formHistory.shift();
      }

      return {
        ...state,
        formValue: { ...state.formValue, ...action.payload },
        formHistory: [...state.formHistory, { formValue: { ...state.formValue, ...action.payload }, focusField: state.focusField }],
      };
    case ACTIONS.UNDO:
      if (state.formHistory.length < 2) return state;
      const historyPop = { ...state.formHistory[state.formHistory.length - 1] };
      state.formHistory.pop();
      return {
        ...state,
        focusField: state.formHistory[state.formHistory.length - 1].focusField,
        formValue: { ...state.formHistory[state.formHistory.length - 1].formValue },
        formFuture: [historyPop, ...state.formFuture],
      };
    case ACTIONS.REDO:
      if (!state.formFuture.length) return state;
      const futurePop = { ...state.formFuture[0] };
      state.formFuture.shift();
      return {
        ...state,
        focusField: futurePop.focusField,
        formValue: futurePop.formValue,
        formHistory: [...state.formHistory, futurePop],
      };
    case ACTIONS.RESET_HISTORY:
      return {
        ...state,
        formHistory: [],
        formFuture: [],
      };
    case ACTIONS.RESET_STATE:
      return {
        focusField: '',
        formValue: {},
        formHistory: [],
        formFuture: [],
      };
    default:
      console.error(`Unhandled action type: ${action.type}`);
      return state;
  }
};

const UndoRedoContext = createContext<UndoRedoContextType | undefined>(undefined);

const UndoRedoProvider: React.FC<UndoRedoProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(undoRedoReducer, {
    focusField: '',
    formValue: {},
    formHistory: [],
    formFuture: [],
  });

  const setFieldFocus = (focusField: string) => dispatch({ type: ACTIONS.SET_FOCUS, payload: focusField });
  const clearFieldFocus = () => dispatch({ type: ACTIONS.REMOVE_FOCUS });
  const setFormValue = (formValue: Record<string, any>) => dispatch({ type: ACTIONS.SET_VALUE, payload: formValue });
  const undo = () => dispatch({ type: ACTIONS.UNDO });
  const redo = () => dispatch({ type: ACTIONS.REDO });
  const resetFormHistory = () => dispatch({ type: ACTIONS.RESET_HISTORY });
  const resetFormState = () => dispatch({ type: ACTIONS.RESET_STATE });

  const value = { ...state, setFieldFocus, clearFieldFocus, setFormValue, undo, redo, resetFormHistory, resetFormState };

  return <UndoRedoContext.Provider value={value}>{children}</UndoRedoContext.Provider>;
};

export { UndoRedoContext, UndoRedoProvider };
export type { UndoRedoState };
