import { ListMessage } from 'src/utils/message/index';

export const renderMessage = (message: any, positionA: any, positionB: any, positionC: any) => {
  return positionA
    ? message.replace('{0}', positionA)
    : positionB
    ? message.replace('{1}', positionB)
    : positionC
    ? message.replace('{2}', positionC)
    : message;
};

export const renderMessageOwner = (message: any, arrs: any) => {
  if (arrs) {
    for (let i = 0; i < arrs.length; i++) {
      message = message.replace('{' + i + '}', arrs[i]);
    }
  }
  return message;
};

export const renderMessageID = (messageID: any, arrs?: any) => {
  let message = ListMessage.message[messageID];
  if (arrs) {
    for (let i = 0; i < arrs.length; i++) {
      message = message.replace('{' + i + '}', arrs[i]);
    }
  }
  return message;
};
